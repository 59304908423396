<template>
  <div>
    <div class="d-flex justify-content-between mt-1 mb-2">
      <div class="ml-3 mt-2">
        <template v-if="request.rowSelected===true">
          <base-button class="red-outline-button pl-5 pr-5 pt-1 pb-1 br-4" @click="$store.commit('showRemoveModal')">
            Delete
          </base-button>
          <base-button class="green-outline-button pl-5 pr-5 pt-1 pb-1 br-4 ml-2"
                       @click="showConfirmDialog('activate')">
            Activate
          </base-button>
          <base-button class="black-outline-button pl-5 pr-5 pt-1 pb-1 br-4 ml-2"
                       @click="showConfirmDialog('deactivate')">
            Deactivate
          </base-button>
        </template>
      </div>
      <div class="mt-1">
        <template v-if="request.rowSelected===false">
          <router-link :to="{name: 'StoreChecklistTemplate', params: {id: this.temp_stage}}">
            <base-button class="btn-secondary-action font-weight-light pcl-5 pcr-5 pt-2 pb-2 br-4 mr-4">Add New</base-button>
          </router-link>
        </template>
      </div>
    </div>

    <checklist-template
      v-loading="loaders.table"
      :tableData="response.templateList"
      :tableColumns="tableColumns"
      :actions="templateActions"
      :pagination="response.pagination"
      @changed-page="changePage"
      @onEdit="onEdit"
      @onCopy="onCopy"
      @columnClicked="columnClicked"
      @onRemove="promptRemove"
      @onTableRowSelected="onTableRowSelected"
    />

    <remove-modal
      :loader="loaders.modals"
      :remove-id="request.removeId"
      module="checklist template"
      @onRemove="confirmRemove"
      modal-text="Deleting the checklist templates can cause pipeline automations and reports to fail.
      Please contact your Baritastic representative if you are unsure how deleting here will affect your automations and reporting."
    ></remove-modal>

    <confirm-action
      :loader="loaders.modals"
      confirm-id="-1"
      @onConfirm="confirmedDialog"
    ></confirm-action>

    <modal :show.sync="modals.deleteRestrictionModal.visible">
      <template slot="header">
        <h5 class="modal-title">Checklist Template Alert!</h5>
      </template>
      <div  class="mt--4 ml--1">
        <span>To Delete Template(s) please contact your account representative.</span>
      </div>
      <template slot="footer">
        <base-button type="secondary" class="btn-gray rounded-btn" @click="modals.deleteRestrictionModal.visible = false">Close</base-button>
      </template>
    </modal>
  </div>
</template>

<script>

import TableWithActionsAndPagination from "@/components/Tables/TableWithActionsAndPagination";
import RemoveModal from "@/components/Modals/RemoveModal";
import ConfirmAction from "@/components/Modals/ConfirmAction";
import _ from "lodash";

export default {
  name: "PreOpList",
  props: ['temp_stage'],
  components: {
    ChecklistTemplate: TableWithActionsAndPagination,
    RemoveModal,
    ConfirmAction
  },
  data() {
    return {
      loaders: {
        table: '',
        modals: false,
      },
      modals: {
        deleteRestrictionModal: {
          text:'',
          visible:false
        }
      },
      response: {
        templateList: [],
        pagination: {},
      },
      templateActions: [
        {
          src: '/img/icons/buttons/copy.svg',
          action: 'copy'
        },
        {
          src: '/img/icons/buttons/edit.svg',
          action: 'edit'
        },
        {
          src: '/img/icons/buttons/delete.svg',
          action: 'delete'
        }
      ],
      request: {
        page: 1,
        rowSelected: false,
        rowsSelected: [],
        bulkStatus: '',
        removeId: -1,
        singleDelete: false,
      },
      tableColumns: [
        {
          prop: "name",
          label: "Template Name",
          minWidth: 100,
        },
        {
          prop: "default_template",
          label: "Default Template",
          minWidth: 100,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 70,
        },
      ],
    }
  },
  methods: {
    changePage(page) {
      this.request.page = page;
      this.getChecklistTemplate();
    },

    promptRemove(row) {
      this.request.singleDelete = true;
      this.request.removeId = row.id;
      if(this.$store.getters.getAuthUser.type === 'sub_user') {
        this.$store.commit('showRemoveModal');
      }
      else {
        this.modals.deleteRestrictionModal.visible = true;
      }
    },

    columnClicked(column) {
      if (column.prop === 'default_template') {
        this.editDefaultTemplate(column.row.id);
      }
    },

    onTableRowSelected(rows) {
      this.request.rowsSelected = rows;
      console.log(rows);
      this.request.rowSelected = rows.length > 0;
    },

    showConfirmDialog(status) {
      this.$store.commit('showConfirmModal');
      this.request.bulkStatus = status;
    },

    confirmRemove() {
      this.deleteChecklists();
    },

    confirmedDialog() {
      this.toggleChecklistStatus();
    },

    onEdit(row) {
      this.$router.push({name: 'EditChecklistTemplate', params: {id: this.temp_stage, rowId: row.id}});
    },

    onCopy(row) {
      this.$router.push({name: 'CopyChecklistTemplate', params: {id: this.temp_stage, rowId: row.id}});
    },

    getChecklistTemplate() {
      let vm = this;
      vm.loaders.table = true;
      axios.get(`${this.$store.getters.getBaseUrl}/api/checklist/templates?temp_stage=${this.temp_stage}&page=${this.request.page}`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.templateList = response.data.data.data;
          vm.response.pagination = response.data.data;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'ChecklistTemplates',
            message: message
          });
        }).finally(() => {
        vm.loaders.table = false;
      })
    },

    editDefaultTemplate(id) {
      let vm = this;
      vm.loaders.table = true;
      let tempStage = this.temp_stage;
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklist/templates/default`, {
        id,tempStage
      }).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.getChecklistTemplate();
        vm.$notify.success({
          title: 'ChecklistTemplates',
          message: 'Default template changed successfully'
        });
      }).catch((error) => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'ChecklistTemplates',
          message: message
        });
      }).finally(() => {
        vm.loaders.table = false;
      })
    },

    deleteChecklists() {
      let vm = this;
      let idsToDelete;
      this.request.singleDelete === false ? idsToDelete = _.map(this.request.rowsSelected, 'id') : idsToDelete = [this.request.removeId];
      vm.loaders.modals = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklist/templates/bulk-delete`, {
        idsToDelete,
      })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.getChecklistTemplate();
        })
        .catch((error) => {
          console.log(error);
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Checklist Templates',
            message: message
          });
        }).finally(() => {
        vm.loaders.modals = false;
        this.request.singleDelete = false;
        this.$store.commit('hideRemoveModal')
      })
    },

    toggleChecklistStatus() {
      let vm = this;
      let idsToToggle = _.map(this.request.rowsSelected, 'id');
      vm.loaders.modals = true;
      axios.post(`${this.$store.getters.getBaseUrl}/api/checklist/templates/toggle-status`, {
        idsToToggle,
        status: this.request.bulkStatus,
      })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.getChecklistTemplate();
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'ChecklistTemplates',
            message: message
          });
        }).finally(() => {
        vm.loaders.modals = false;
        this.$store.commit('hideConfirmModal')
      })
    }
  },
  mounted() {
    this.getChecklistTemplate();
  }
}
</script>

<style scoped>

</style>
